import Select from "react-select";
import { styles } from "../../components/utils/reactSelect";
const CardPayment = ({t, countries, validCountry, loading, nameInvalid, setCardName, invalidCarnum, setCardNum, selectedCountry, setexpYear, noExpYear, expYear, expMonth, setexpMonth, noExpMonth, noCVV, setCVV, setSelectedCountry, submit}) => {
    const year = new Date().getFullYear();
    return (
        <div className="payment-form">
            <div className="form-group mb-3">
                <label htmlFor="name" className={nameInvalid?"text-danger":""}>{t("FORM.cardName")}<span className="text-danger">*</span></label>
                <input className="form-control" id="name" type="text" placeholder="Nkolo Jean Francois" name="name" onChange={(e)=>{setCardName(e.target.value)}}/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="cardnum" className={invalidCarnum?"text-danger":""}>{t("FORM.cardNum")}<span className="text-danger">*</span></label>
                <input className="form-control" id="cardnum" type="number" placeholder="5531886652142950" name="cardnum" onChange={(e)=>{setCardNum(e.target.value)}}/>
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="form-group mb-3">
                        <label htmlFor="cardexp" className={noExpYear?"text-danger":""}>{t("FORM.cardExpY")}<span className="text-danger">*</span></label>
                        <Select className="basic-single"
                        id="cardExpyear"
                        styles={styles}
                        isSearchable
                        isDisabled={loading}
                        inputId="expyear"
                        value={expYear}
                        
                        isLoading={loading}
                        onChange={(val)=>{setexpYear(val)}}
                        options = {Array.from({length:10},(e,i)=>JSON.parse(`{"label":${year+i}, "value":${year+i}}`))}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group mb-3">
                        <label htmlFor="cardexp" className={noExpMonth?"text-danger":""}>{t("FORM.cardExpM")}<span className="text-danger">*</span></label>
                        <Select className="basic-single"
                        id="cardExpmonth"
                        styles={styles}
                        isSearchable
                        isDisabled={loading}
                        inputId="expmonth"
                        value={expMonth}
                        
                        isLoading={loading}
                        onChange={(val)=>{setexpMonth(val)}}
                        options = {Array.from({length:12},(e,i)=>JSON.parse(`{"label":${i+1}, "value":${i+1}}`))}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group mb-3">
                        <label htmlFor="cardcvv" className={noCVV?"text-danger":""}>{t("FORM.cardCVV")}<span className="text-danger">*</span></label>
                        <input className="form-control" id="cardcvv" type="number" placeholder="564" name="cardcvv" onChange={(e)=>{setCVV(e.target.value)}}/>
                    </div>
                </div>
            </div>
            <div className="form-group mb-3">
                {!validCountry && <label><p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.country")}</p></label>}
                {validCountry && <label htmlFor="country">{t("FORM.country")} <span className="text-danger">*</span></label>}
                <Select className="basic-single"
                    id="country"
                    styles={styles}
                    isSearchable
                    isDisabled={loading}
                    inputId="country"
                    value={selectedCountry}
                    
                    isLoading={loading}
                    onChange={(val)=>{setSelectedCountry(val)}}
                    options={countries?.map((i)=>{
                    return {label: t(`txtCountry.${i.code2}`), value: i.id, url: i.url, name:i.name, phone:i.phone, code:i.code2}
                    }) ?? []}/></div>
            <div className="method-desc text-center mt-10">
                <p className="mt-10" style={{fontSize:"8pt", lineHeight:1.5}}>{t("FORM.paymentDesc")}</p>
            </div>
            <div className="col-12 text-center">
                <button className="btn btn-primary  btn-block mt-2 w-100 form-btn" disabled={loading} style={{minHeight:"50px", border: "solid 1px #1d3461"}} onClick={(e)=>submit()}>{t("FORM.processPay")}</button>
            </div>
        </div>
    )

}
export default CardPayment;