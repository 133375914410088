export const fr = {
    textHome: "Acceuil",
    textPrivacyPolicy: "Plolitique de Confidentialité",
    textAboutUs: "A Propos",
    textService: "Services",
    textTeam: "Equipe",
    textBlog: "Blog",
    textContact: "Contact",
    textLngFrench: "Francais",
    textLngEnglish: "Anglais",
    textTime:"8h - 17h",
    textDay:"Lun - Ven: ",
    textCall: "Appelez:",
    textConsultation: "Prenez un RDV",
    textStart:"Demmarez Ici",
    textHelp:"Besoin d'aide ?",
    textHero11: "Votre solution unique pour les services d'analyse et les logiciels financiers",
    textHero12:"Prenez une longueur d'avance grâce à des décisions fondées sur des données avec B.K. Research & Analytics.",
    textHero21: "Prendre des décisions éclairées avec notre application CR²-SME",
    textHero22:"Notre application de notation à la pointe de la technologie combine des données financières et qualitatives pour vous donner une image précise de la solvabilité et du potentiel de croissance",
    textHero23:" des petites et moyennes entreprises.",
    textHero31: "Libérez la puissance des données grâce à nos services d'analyse",
    textHero32:"De l'analyse du panier d’achat, à l'analyse des sentiments (texte), en passant par les solutions de Business Intelligence, notre équipe d'experts travaillera en étroite collaboration avec vous pour",
    textHero33: " découvrir des opportunités cachées et stimuler la croissance.",
    textAbout:"A PROPOS DE NOUS",
    textAbdescp: "B.K. Research & Analytics est une startup basée à Yaoundé, au Cameroun. Nous accompagnons les petites et moyennes entreprises et les institutions financières dans l’analyse et l'exploitation des données structurées et non structurées.",
    textAbserv1:"Notation Financière PME Saas",
    textAbserv2:"Projections Financières Saas",
    textAbserv3:"Business Intelligence",
    textAbserv4:"Services Analytics",
    textOurServices: "Nos Services",
    textServdescp: "Libérez tout votre potentiel grâce à nos applications et services.",
    textCreditrisktitle:"Credit Risk Rating - PME",
    textCreditrisk:{
        title:"Notation Financière - PME",
        resume:"Grâce à notre application, vous pouvez évaluer en toute confiance la solvabilité et le potentiel de croissance des PME.",
        subTitle1:"Prenez des décisions éclairées grâce à notre application de notation des PME ",
        description1:"Êtes-vous une banque ou une institution financière à la recherche d'un moyen fiable et abordable d'évaluer les performances financières et la solvabilité de vos clients PME? Si oui, vous avez besoin de notre application de notation financière des PME. ",
        description2:"Notre application est un outil en ligne qui utilise des algorithmes avancés et des analyses de données pour évaluer la situation financière des PME et générer un rapport complet. Ce rapport comprend la notation financière de la PME, qui est un(e) code(lettre) reflétant la santé financière et le niveau de risque de la PME.",
        subSection1:[
            {
            title:"Données",
            description:"CR²-SME intègre des données provenant de deux sources, à savoir les états financiers (hard data) et les informations sur l’entrepreneur et l’environnement de l’entreprise (soft data).",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Automatisation",
            description:"CR²-SME permet d'automatiser un processus complexe à savoir l'évaluation du risque de crédit, ce qui a un impact sur la tarification des prêts et la célérité de prise de décision concernant les demandes de crédit.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Avantages",
        benefit1:"Avec l'application CR²-SME, la banque peut améliorer l'efficacité et la précision de l'évaluation du risque de crédit, réduire le coût et le temps de traitement du crédit, et améliorer la gestion et le suivi de son portefeuille.",
        benefit2:"L’application CR²-SME permet d'augmenter les opportunités de crédit pour les PME, avec des taux d'intérêt plus bas, des demandes de prêt traitées avec plus de célérité.",
        other:"Cette application web a été développée par les africains, tout en prenant en compte les réalités économiques et manageriales de l'Afrique Subsaharienne.",
        image0:"/assets/img/service/credit_1.jpg",
        image1:"/assets/img/service/service-details/credit_2.jpg",
        image2:"/assets/img/service/service-details/credit_3.jpg",
        characteristics:[
            " Données qualitatives ",
            " Etats financiers ",
            " Plus de 150 ratios calculés"
        ],
        brochure:"",
        subSection2:[
            {
            title:"Credit Risk Rating - Basic",
            input:"Only Financial Data",
            output:"Simplified Rating Report",
            Onboarding:"100 000 CFA",
            price_transaction:"60 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Credit Risk Rating - Professionnal",
            input:"Financial Data, Soft Data",
            output:"Simplified Rating Report",
            Onboarding:"100 000 CFA",
            price_transaction:"75 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Credit Risk Rating - Corporate",
            input:"Financial Data, Soft Data",
            output:"Full Detailed Rating Report, Word+Excel Report",
            Onboarding:"100 000 CFA",
            price_transaction:"90 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Credit Risk Rating - Advanced",
            input:"Financial Data, Soft Data",
            output:"All Corporate characteristics + API",
            Onboarding:"100 000 CFA",
            price_transaction:"110 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            }
        ]
    },
    textFinancialfo:{
        title:"Projections Financières - PME",
        resume:"Avec notre application, produire les états financiers prévisionnels avec précision n’a jamais été aussi simple.",
        subTitle1:"Des prévisions financières précises pour une croissance durable",
        description1:"Voulez-vous planifier la conduite de votre entreprise et prendre de meilleures décisions financières pour votre petite et moyenne entreprise (PME) ? Si oui, vous avez besoin de notre application qui fait des prévisions financières des états financiers des PME. Notre application est un outil puissant qui analyse vos données financières passées, actuelles et génère des prévisions précises et fiables pour votre performance future. Votre compte de résultat, votre bilan, votre tableau de flux de trésorerie et vos principaux rapports financiers prévisionnels peuvent être préparés à l’aide de notre application. ",
        description2:"De plus, vous pouvez personnaliser vos prévisions en ajustant une variété de paramètres, y compris le taux de croissance du chiffre d’affaires, la structure des charges, la structure du capital, le taux d’intérêt des emprunts. Notre application vous aidera à planifier votre budget, à évaluer votre rentabilité, à optimiser votre flux de trésorerie, à identifier vos forces et faiblesses, à améliorer votre santé financière. ",
        subSection1:[
            {
            title:"Facilité d'utilisation",
            description:"Aucune expertise comptable ou financière n'est requise pour utiliser notre application.  Il vous suffit de télécharger vos états financiers [DSF modèle OHADA, fichier MS Excel] et notre application fera le reste.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Sécurisé et confidentiel",
            description:"Nous utilisons des systèmes de cryptage, d'authentification et de sauvegarde pour protéger vos données contre tout accès non autorisé, toute perte ou tout dommage. Nous ne partageons pas vos données avec des tiers sans votre consentement.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }//,
            // {
            //   title:"Interactif et visuel",
            //   description:". You can view your forecasts in tables, charts, or graphs. You can also compare your forecasts with different scenarios, benchmarks, or industry standards. You can also export or print your forecasts for further analysis or presentation.",
            //   image:"/assets/img/service/service-details/icon-3.svg"
            // }
        ],
        benefit:"Avantages",
        benefit1:"Notre application offre plusieurs atouts aux PME. Il s’agit notamment d’une meilleure prise de décision, d’un meilleur accès au financement, d’une amélioration de la gestion des flux de trésorerie, d’une augmentation de l’efficacité opérationnelle. ",
        benefit2:"En adoptant notre application, les PME peuvent améliorer leur performance financière, réduire les risques et se positionner pour le succès à long terme.",
        other:"Que vous ayez besoin d'obtenir un prêt, d'attirer des investisseurs ou de gérer votre entreprise plus efficacement, notre application vous fournira les informations et les conseils dont vous avez besoin.",
        image0:"/assets/img/service/financial_forecasting_1.jpg",
        image1:"/assets/img/service/service-details/financial_forecasting_2.jpg",
        image2:"/assets/img/service/service-details/financial_forecasting_3.jpg",
        characteristics:[
            " Facilité d'utilisation ",
            " Interactif et visuel ",
            " Sécurisé et confidentiel "
        ],
        brochure:"",
        subSection2:[
            {
            title:"Financial Forecasting - Professionnal",
            input:"Financial Data",
            output:"Simplified Forecasting Report, Simplified Analysis Report, PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"230 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Financial Forecasting - Corporate",
            input:"Financial Data",
            output:"Full Detailed Forecasting Report, Full Detailed Analysis Report, Word + Excel + PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"300 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            }
        ]

    },
    textCapitalbud:{
        title:"Capital Budgeting - PME",
        resume:"Simulez différents scénarios, évaluez les ris-ques dans vos décisions d'investissement, tout en intégrant l’aléa, grâce à notre application.",
        subTitle1:"Prenez des décisions d'investissement plus intelligentes avec notre application d’analyse d’investissement",
        description1:"Vous cherchez un moyen d'évaluer et de comparer différentes options d'investissement pour votre entreprise ? Voulez-vous savoir quel projet vous donnera le meilleur retour sur votre investissement ? Avez-vous besoin d'un outil simple et fiable pour vous aider à planifier votre capital d’investissement ?",
        description2:"Si vous avez répondu oui à l'une de ces questions, alors vous avez besoin de notre application Capital Budgeting. Notre application intègre des variables aléatoires dans le modèle, vous permettant de simuler différents scénarios et de prendre des décisions éclairées sur la base de projections précises et réalistes.",
        subSection1:[
            {
            title:"Plusieurs Indicateurs",
            description:"L'application peut appliquer différentes méthodes de budgétisation des investissements, telles que la valeur actuelle nette, le taux de rendement interne, la période de récupération et l'indice de rentabilité, afin d'évaluer et de comparer les options d'investissement.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Analyse de la Sensibilité",
            description:"L'application permet de créer et de comparer différents scénarios et d'effectuer une analyse de sensibilité pour voir comment les résultats changent en fonction de différentes variables et de différents paramètres.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Avantages",
        benefit1:"L'application aide l'utilisateur à prendre des décisions d'investissement plus judicieuses et mieux informées, fondées sur des données et des analyses, plutôt que sur l'intuition ou la conjecture.",
        benefit2:"L'application permet à l'utilisateur de gagner du temps et de l'argent en automatisant et en simplifiant le processus de budgétisation des investissements, en réduisant les erreurs et en fournissant des calculs précis et fiables.",
        other:"Ne manquez pas cette occasion d'améliorer votre processus d'établissement du budget d'investissement et de développer votre entreprise. Essayez notre application dès aujourd'hui et voyez la différence.",
        image0:"/assets/img/service/capital_budgeting_1.jpg",
        image1:"/assets/img/service/service-details/capital_budgeting_2.jpg",
        image2:"/assets/img/service/service-details/capital_budgeting_3.jpg",
        characteristics:[
            "Interface facile à utiliser ",
            "Calculs précis et fiables",
            "Scénarios et paramètres personnalisables"
        ],
        brochure:"",
        subSection2:[
            {
            title:"Capital Budgeting - Professionnal",
            input:"Financial Data",
            output:"Simplified Budgeting Report, Simplified Analysis Report, PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"230 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            },
            {
            title:"Capital Budgeting - Corporate",
            input:"Financial Data",
            output:"Full Detailed Budgeting Report, Full Detailed Analysis Report, Word + Excel + PDF downloadable Report",
            Onboarding:"100 000 CFA",
            price_transaction:"300 000 CFA",
            image:"/assets/img/service/service-details/price_2_2.jpg"
            }
        ]
    },
    textMarketbas:{
        title:"Analyse du Panier d’Achat",
        resume:"En analysant vos données transactionnelles, nous identifions les schémas de consomma-tion des clients et des niches de croissance.",
        subTitle1:"Optimisez Vos Stratégies Commerciales et Marketing",
        description1:"L'analyse du panier de consommation est une approche de modélisation basée sur la notion que si vous achetez un ensemble d'articles, vous êtes plus (ou moins) susceptible d'acheter un autre ensemble d'articles. Elle nous permet de déterminer le comportement d'achat des clients et d'influencer leurs choix au cours du processus d'achat.",
        description2:"L'analyse des paniers de marché cherche à trouver des relations entre les articles d'un panier (espace) et entre des paniers successifs lorsque des données temporelles sont disponibles (temps). Elle fournit des indices sur ce qu'un client aurait pu acheter si l'idée lui était venue.",
        subSection1:[
            {
            title:"Experience Client",
            description:"Le MBA permet de réduire le temps d’attente pour l’achat des articles et de garantir leur disponibilité grâce à une meilleure gestion de stock.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Optimisation du Revenu",
            description:"Le MBA permet une réduction des couts, une suggestion pertinente d’articles à acheter et une création d’assortiments attractifs. ", //It helps optimize product placement by arranging products that are frequently bought together.
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Avantages",
        benefit1:"Le MBA aide à stimuler les ventes et le retour sur investissement en concevant des promotions efficaces, des opportunités de ventes croisées et des recommandations personnalisées.", //Il permet d'optimiser le placement des produits en associant les produits qui sont fréquemment achetés ensemble. //It also helps reduce cost.
        benefit2:"Cela permet à l'entreprise d'améliorer la satisfaction des clients et sa performance globale en prenant des décisions éclairées.",//"L'intérêt de l'analyse du panier d'achat est de trouver les articles que les acheteurs désirent acheter et d'augmenter les ventes en comprenant mieux les modèles d'achat des clients ", 
        other:"Lors de l'utilisation du MBA, l'ampleur de l'augmentation des ventes dépend de la sensibilité au prix des clients et du niveau de saturation des affinités. Cette augmentation peut varier entre 0,5 % et 10 %.",
        image0:"/assets/img/service/basket_1.jpg",
        image1:"/assets/img/service/service-details/basket_2.jpg",
        image2:"/assets/img/service/service-details/basket_3.jpg",
        characteristics:[
            "Prix",
            "Fideliser la clientèle",
            "Tactiques de vente croisée et de vente incitative"
        ],
        brochure:"",
        // subSection2:[]
    },
    textSentiana:{
        title:"Analyse du Langage Naturel",
        resume:"Que disent vos clients/prospects à votre sujet ? Nous identifions la polarité de leur sentiment vis-à-vis de votre organisation.",
        subTitle1:"Connaître les opinions de vos clients.",
        description1:"L'analyse des sentiments est une technique qui utilise le traitement du langage naturel (NLP) pour déterminer si un texte est positif, négatif ou neutre. Elle peut aider les entreprises à comprendre les réactions et les opinions des clients.",
        description2:"L'analyse des sentiments vous aide à réduire le taux d'attrition des clients en traitant les commentaires négatifs et en améliorant la fidélisation. ",
        subSection1:[
            {
            title:"Monitoring Réseaux Sociaux",
            description:"Nous pouvons suivre le sentiment des utilisateurs à l'égard d'une marque, d'un produit ou d'un sujet sur les plateformes de réseaux sociaux.",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Analyse des Produits",
            description:"Elle permet d'évaluer les forces et les faiblesses d'un produit sur la base des avis des clients",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Avantages",
        benefit1:"L'analyse des sentiments permet d'identifier les forces et les faiblesses de votre produit ou service sur la base des commentaires des clients.  ", //It allows handle multiple customers simultaneously and prioritize the most urgent or negative.
        benefit2:"Elle peut vous aider à comprendre les tendances du marché et les préférences des clients et à élaborer une stratégie de croissance efficace. ",
        other:"L'intérêt de l'analyse des sentiments est de comprendre les émotions et les opinions des personnes à partir de leurs textes. Elle peut vous aider à mieux comprendre les réactions des clients, les tendances du marché, les performances des produits, etc.",
        image0:"/assets/img/service/sentiment_1.jpg",
        image1:"/assets/img/service/service-details/sentiment_2.jpg",
        image2:"/assets/img/service/service-details/sentiment_3.jpg",
        characteristics:[
            "Monitoring de la marque",
            "Gestion de la reputation",
            "Voix des clients"
        ],
        brochure:"",
        // subSection2:[]
    },
    textAnalytics:{
        title:"Services Analytics",
        resume:"Nous disposons l'expertise nécessaire pour accompagner votre entreprise dans sa croi-ssance grace aux sciences de données.",
        subTitle1:"Exploitez le potentiel de vos données",
        description1:"L'analyse des données est un service par lequel nous aidons les entreprises à tirer des enseignements de leurs données et à prendre de meilleures décisions. Les services d'analyse de données peuvent impliquer divers éléments tels que l'entreposage de données, la visualisation de données, la science des données, le traitement des données massives, etc.",
        description2:"",
        subSection1:[
            {
            title:"Optimisation des Opérations",
            description:"Optimiser les flux de travail pour améliorer la productivité et l'engagement des employés. Contrôler les données pour améliorer la cybersécurité. Rationaliser les opérations pour être plus efficace. ",
            image:"/assets/img/service/service-details/icon-1.svg"
            },
            {
            title:"Experience Client",
            description:"Suivre les comportements des consommateurs pour enrichir leur expérience. Suivre les tendances du marché pour lancer de nouveaux produits et services. Mesurer les performances des campagnes de marketing.",
            image:"/assets/img/service/service-details/icon-2.svg"
            }
        ],
        benefit:"Avantages",
        benefit1:"Vous pouvez personnaliser l'expérience du client grâce à l'analyse des données en adaptant vos messages marketing et vos services à chaque consommateur. Les menaces qui pèsent sur votre entreprise, telles que la fraude, les cyberattaques et les problèmes de conformité, peuvent être évitées ou atténuées.",
        benefit2:"",//"L'analyse des données peut aider les entreprises à identifier de nouveaux prospects, à lancer de nouveaux produits ou services, ou à améliorer la fidélité des clients, autant d'éléments qui conduisent à une augmentation des revenus.",
        other:"En moyenne, les entreprises qui accordent une grande importance aux données voient une augmentation de 5,32 % de leur chiffre d'affaires annuel grâce à une meilleure utilisation des données.",
        image0:"/assets/img/service/analytics_1.jpg",
        image1:"/assets/img/service/service-details/analytics_2.jpg",
        image2:"/assets/img/service/service-details/analytics_3.jpg",
        characteristics:[
            "Surveillance du marché ",
            "Rationalisation des opérations ",
            "Suivi des comportements des consommateurs "
        ],
        brochure:"",
        // subSection2:[
        // ]
    },
    textProcess:"Notre Processus de travail",
    textProtitle:"Un facteur clé de notre succès.",
    textProitem11:"Phase d'Orientation",
    textProitem12:"Cette phase consiste à établir le premier contact avec le client, à identifier les résultats escomptés et à réaliser un diagnostic sur le terrain pour évaluer la situation actuelle et les facteurs qui l’influencent.",
    textProitem21:"Phase de Recherche et de Solution ",
    textProitem22:"Cette phase consiste à spécifier les objectifs, à concevoir et mener la recherche, à analyser les données, à élaborer la(les) solution(s) et à la(les) présenter au client.",
    textProitem31:"Phase de mise en œuvre ",
    textProitem32:"Cette phase consiste à prendre des mesures efficaces, à suivre et à évaluer les résultats, et à concevoir des aides à la continuité pour assurer la pérennité de la solution.",
    textPropro:"Projets réalisés",
    textAsktitle0:"ask anything",
    textAsktitle1:"Pour toute question, ",
    textAsktitle2:"contactez nous",
    textAskdesp1:"N'hésitez pas à nous contacter si vous avez des questions ou des commentaires. Nous sommes toujours ravis de vous entendre et de vous accompagner dans la recherche de solutions à vos be-soins. ",
    textAskad:"Ecrivez",
    textAskdesp2:" nous ou applelez +237 696 888 241. Nous espérons avoir bientôt de vos nouvelles.",
    textAsksat:" Clients Satisfaits",
    textAskfaq:{
        que1:"Qu'est-ce que le conseil aux entreprises ?",
        ans1:"Le conseil en affaires est un service fourni par des professionnels qui offrent des orientations, l’assistance et des conseils d'experts aux organisations qui cherchent à améliorer leurs processus d'affaires, leurs stratégies, leurs opérations et leur rentabilité.",
        que2:"Quels types d'entreprises ont généralement recours aux services de conseil ?",
        ans2:"Les organisations de toutes tailles ont recours aux services de conseil, les grandes entreprises étant souvent les premières à le faire. Cependant, les PME ont plus besoin de ces services, mais elles ne les demandent très souvent pas.",
        que3:"Quels sont les avantages du recours à un service de conseil aux entreprises ?",
        ans3:"En offrant une évaluation impartiale et experte de la situation et des besoins de votre entreprise, le service de conseil aux entreprises peut vous aider à améliorer votre productivité, votre confiance, votre capacité à résoudre les problèmes et vos compétences technologiques.",
        que4:"Combien coûtent les services de conseil ?",
        ans4:"Le coût des services de conseil dépend de plusieurs facteurs, tels que le type, la durée et le lieu du service, ainsi que l'expérience et l'expertise du consultant.",
    },
    textOurTeam:{
        title:"notre equipe ",
        slogpart1:"Le Personnel Adéquat est notre ",
        slogpart2:"Atout le Plus Important."
    },
    textBlog:{
        part1:"Construire des Entreprises Performantes",
        part2:"Ensemble"
    },
    textFaq1:{
        que1:"Qu'est-ce que le conseil aux entreprises ?",
        ans1:"Le conseil en affaires est un service fourni par des professionnels qui offrent des orientations, l’assistance et des conseils d'experts aux organisations qui cherchent à améliorer leurs processus d'affaires, leurs stratégies, leurs opérations et leur rentabilité.",
        que2:"Quels types d'entreprises ont généralement recours aux services de conseil ?",
        ans2:"Les organisations de toutes tailles ont recours aux services de conseil, les grandes entreprises étant souvent les premières à le faire. Cependant, les PME ont plus besoin de ces services, mais elles ne les demandent très souvent pas.",
        que3:"Quels sont les avantages du recours à un service de conseil aux entreprises ?",
        ans3:"En offrant une évaluation impartiale et experte de la situation et des besoins de votre entreprise, le service de conseil aux entreprises peut vous aider à améliorer votre productivité, votre confiance, votre capacité à résoudre les problèmes et vos compétences technologiques.",
        que4:"Combien coûtent les services de conseil ?",
        ans4:"Le coût des services de conseil dépend de plusieurs facteurs, tels que le type, la durée et le lieu du service, ainsi que l'expérience et l'expertise du consultant.",
    },
    textFaq2:{
        que1:"Qu'est-ce que le CR²-SME ?",
        ans1:"CR²-SME est un outil en ligne qui évalue le risque de crédit des PME, grâce à leurs états financiers et leurs caractéristiques sociales [entrepreneur / dirigeant] avant une décision d’octroi de crédit.",
        que2:"Quels types d'entreprises utilisent généralement CR²-SME ?",
        ans2:"CR²-SME est un service mis à la disposition des institutions financières. Il est particulièrement adapté aux banques et aux institutions de microfinance.",
        que3:"Quels sont les avantages de l'utilisation de CR²-SME?",
        ans3:"CR²-SME peut aider une institution financière à évaluer et à améliorer sa solidité financière en mesurant sa performance sur la base de six facteurs : la suffisance du capital, la qualité des actifs, la gestion du risque et la sensibilité au risque de crédit.  Il peut également aider une institution financière à se conformer aux lois et réglementations sur la protection des consommateurs en examinant sa conformité aux obligations en matière de prêt équitable, de confidentialité et de divulgation.",
        que4:"Quel est le prix de CR²-SME ?",
        ans4:"Pour plus d'informations sur le prix, suivez le ",
        ans5:"lien",
        ans6:", allez en bas de page. ",
    },
    textFaq3:{
        que1:"Qu'est-ce que l'analyse du panier d’achat (Market Basket Analysis) ?",
        ans1:"L'analyse du panier d’achat est une technique de modélisation qui repose sur la théorie selon laquelle si vous achetez un groupe d'articles, vous êtes plus (ou moins) susceptible d'acheter un autre groupe d'articles.",
        que2:"Quels types d'entreprises utilisent généralement l'analyse du panier d'achat ?",
        ans2:"L'analyse des paniers d’achat est généralement utilisée par les détaillants (la grande distribution), tels que les supermarchés, les magasins en ligne et les restaurants, mais elle peut également être appliquée à d'autres secteurs, tels que la banque, la santé et l'éducation.",
        que3:"Quels sont les avantages de l'analyse des paniers d’achat ?",
        ans3:"L'analyse des paniers de marché peut aider les entreprises à comprendre le comportement de leurs clients et à augmenter leurs ventes grâce aux possibilités de ventes croisées et de ventes incitatives.",
        que4:"Combien coûte l'analyse du panier d'achat ?",
        ans4:"Pour plus d'informations sur le prix, veuillez nous contacter à l'adresse ",
        ans5:"contact@bkresearchandanalytics.com",
    },
    textFaq4:{
        que1:"Qu’est-ce que la prévision des états financiers ?",
        ans1:"Les prévisions financières désignent les projections financières réalisées pour faciliter la prise de décision en vue de déterminer les performances futures de l'entreprise. ",
        que2:"Quel type d'entreprise utilise généralement les logiciels de prévisions financières ?",
        ans2:"Les entreprises qui utilisent généralement les logiciels de prévisions financières sont les sociétés technologiques, les sociétés de services et les organisations de toutes tailles qui s'appuient sur une équipe pour recueillir des données, déterminer les tendances du marché et les changements.",
        que3:"Quels sont les avantages de l'utilisation d'un logiciel de prévisions financières ?",
        ans3:"Les logiciels de prévisions financières offrent plusieurs avantages aux petites et moyennes entreprises (PME) tels que l'Amélioration de la prise de décision, la Gestion améliorée des flux de trésorerie, l'Augmentation de la rentabilité, une Meilleure affectation des ressources. Cependant, leur efficacité dépend de la qualité des données saisies et de l'exactitude des hypothèses formulées au cours du processus de prévision.",
        que4:"Combien coûtent les services de prévisions financières ?",
        ans4:"Pour plus d'informations sur le prix, suivez le ",
        ans5:"lien",
        ans6:", allez en bas de page.",
    },
    textCont:{
        title:"Prenez contact avec nous !",
        subtitle:"En quoi pouvons-nous vous aider ?",
        name:"Votre Nom",
        email:"Adresse Email",
        message:"Votre Message Ici",
        submit:"Soumettre",
        address:"Adresse",
        mail:"Nous écrire",
        tel:"Téléphone"
    },
    textBl:{
        cat:"Catégories",
        it1:"Intélligence Artificielle",
        it2:"Finance",
        it3:"Affaire",
        rec:"Article Recent",
        wk:"Travailler avec nous",
        txt:"Envoyez nous votre candidature à contact@bkresearchandanalytics.com"
    },
    txtCountry:{
        "AF": "Afghanistan",
        "AX": "Åland",
        "AL": "Albanie",
        "DZ": "Algérie",
        "AS": "Samoa américaines",
        "AD": "Andorre",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctique",
        "AG": "Antigua et Barbuda",
        "AR": "Argentine",
        "AM": "Arménie",
        "AW": "Aruba",
        "AU": "Australie",
        "AT": "Autriche",
        "AZ": "Azerbaïdjan",
        "BS": "Bahamas",
        "BH": "Bahreïn",
        "BD": "Pakistan oriental",
        "BB": "Barbade",
        "BY": "Biélorussie",
        "BE": "Belgique",
        "BZ": "Belize",
        "BJ": "Bénin",
        "BM": "Bermudes",
        "BT": "Bhoutan",
        "BO": "Bolivie",
        "BQ": "Pays-Bas caribéens",
        "BA": "Bosnie-Herzégovine",
        "BW": "Botswana",
        "BV": "Île Bouvet",
        "BR": "Brésil",
        "IO": "Territoire britannique de l'océan Indien",
        "VG": "Îles Vierges britanniques",
        "BN": "Brunéi Darussalam",
        "BG": "Bulgarie",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "CV": "Cap-Vert",
        "KH": "Cambodge",
        "CM": "Cameroun",
        "CA": "Canada",
        "KY": "Iles Caïmanes",
        "CF": "République Centrafricaine",
        "TD": "République du Tchad",
        "CL": "Chili",
        "CN": "République populaire de Chine",
        "CX": "Île Christmas",
        "CC": "Îles Cocos",
        "CO": "Colombie",
        "KM": "Comores (Archipel)",
        "CK": "Îles Cook",
        "CR": "Costa Rica",
        "HR": "Croatie",
        "CU": "Cuba",
        "CW": "Curaçao",
        "CY": "Chypre",
        "CZ": "République tchèque",
        "CD": "République Démocratique du Congo",
        "DK": "Denmark",
        "DJ": "République de Djibouti",
        "DM": "Dominique",
        "DO": "République dominicaine",
        "EC": "Équateur",
        "EG": "Égypte",
        "SV": "Salvador",
        "GQ": "Guinée équatoriale",
        "ER": "Érythrée",
        "EE": "Estonie",
        "SZ": "Royaume du Swaziland",
        "ET": "Éthiopie",
        "FK": "Îles Malouines",
        "FO": "Îles Féroé",
        "FJ": "Îles Fidji",
        "FI": "Finlande",
        "FR": "République Française",
        "GF": "Guyane Française",
        "PF": "Polynésie française",
        "TF": "Terres Australes et Antarctiques Françaises",
        "GA": "République Gabonaise",
        "GM": "Gambie",
        "GE": "Géorgie",
        "DE": "Allemagne",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Grèce",
        "GL": "Groenland",
        "GD": "Grenade",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernesey",
        "GN": "Guinée-Conakry",
        "GW": "Guinée-Bissau",
        "GY": "Guyane britannique",
        "HT": "Haïti",
        "HM": "Îles Heard-et-MacDonald",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hongrie",
        "IS": "Islande",
        "IN": "Inde",
        "ID": "Indonésie",
        "IR": "Iran (République islamique de)",
        "IQ": "Irak",
        "IE": "Irlande",
        "IM": "Île de Man",
        "IL": "Israël",
        "IT": "Italie",
        "CI": "République de Côte d'Ivoire",
        "JM": "Jamaïque",
        "JP": "Japon",
        "JE": "Bailliage de Jersey",
        "JO": "Jordanie",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "XK": "Kosovo",
        "KW": "Koweït",
        "KG": "Kirghizstan",
        "LA": "Laos",
        "LV": "Lettonie",
        "LB": "Liban",
        "LS": "Lesotho",
        "LR": "Libéria",
        "LY": "Libye",
        "LI": "Liechtenstein",
        "LT": "Lituanie",
        "LU": "Grand-Duché de Luxembourg",
        "MO": "Macau",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaisie",
        "MV": "Maldives",
        "ML": "République du Mali",
        "MT": "Malte",
        "MH": "îles;Îles Marshall",
        "MQ": "Martinique",
        "MR": "Mauritanie",
        "MU": "Île Maurice",
        "YT": "Mayotte",
        "MX": "Mexique",
        "FM": "États fédérés de Micronésie",
        "MD": "République de Moldavie",
        "MC": "Principauté de Monaco",
        "MN": "Mongolie",
        "ME": "République du Monténégro",
        "MS": "Montserrat",
        "MA": "Royaume du Maroc",
        "MZ": "Mozambique",
        "MM": "Birmanie",
        "NA": "Namibie",
        "NR": "Nauru",
        "NP": "Népal",
        "AN": "Antilles Néerlandaises",
        "NC": "Nouvelle Calédonie",
        "NZ": "Nouvelle Zélande",
        "NI": "Nicaragua",
        "NE": "République du Niger",
        "NG": "Nigéria",
        "NU": "Nioué",
        "NF": "Ile de Norfolk",
        "MP": "Îles Mariannes du Nord",
        "KP": "Corée du Nord",
        "MK": "Macédoine du Nord",
        "NO": "Norvège",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palaos",
        "PS": "Palestine",
        "PA": "Panamá",
        "PG": "Papouasie-Nouvelle-Guinée",
        "PY": "Paraguay",
        "PE": "Pérou",
        "PH": "Philippines",
        "PN": "Îles Pitcairn",
        "PL": "Pologne",
        "PT": "Portugal",
        "PR": "Porto Rico",
        "QA": "Qatar",
        "CG": "République du Congo",
        "RE": "La Réunion",
        "RO": "Roumanie",
        "RU": ";Russie",
        "RW": "République du Rwanda",
        "BL": "Saint Barthélemy",
        "SH": "Île Sainte-Hélène",
        "KN": "Saint-Christophe-et-Niévès",
        "LC": "Sainte-Lucie",
        "MF": "Collectivité de Saint-Martin",
        "PM": "Collectivité Territoriale de Saint-Pierre et Miquelon",
        "VC": "Saint-Vincent-et-les-Grenadines",
        "WS": "Samoa",
        "SM": "Saint-Marin",
        "ST": "Sao Tomé-et-Principe",
        "SA": "Arabie saoudite",
        "SN": "Sénégal",
        "RS": "Serbie",
        "CS": "Serbie-et-Monténégro",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapour",
        "SX": "État de Saint-Martin",
        "SK": "République slovaque",
        "SI": "Slovénie",
        "SB": "Îles Salomon",
        "SO": "Somalie",
        "ZA": "Afrique du Sud",
        "GS": "Géorgie du Sud et îles Sandwich du Sud",
        "KR": "Corée du Sud",
        "SS": "Soudan du Sud",
        "ES": "Espagne",
        "LK": "Sri Lanka",
        "SD": "Soudan",
        "SR": "Surinam",
        "SJ": "Svalbard et Île Jan Mayen",
        "SE": "Suède",
        "CH": "Confédération Suisse",
        "SY": "République arabe syrienne",
        "TW": "Taïwan",
        "TJ": "Tadjikistan",
        "TZ": "Tanzanie",
        "TH": "Thaïlande",
        "NL": "Pays-Bas",
        "TL": "Timor Oriental",
        "TG": "République Togolaise",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad et Tobago",
        "TN": "Tunisie",
        "TR": "Turkey",
        "TM": "Turkménistan",
        "TC": "Îles Turks et Caïques",
        "TV": "Tuvalu",
        "UG": "Ouganda",
        "UA": "Ukraine",
        "AE": "Émirats Arabes Unis",
        "GB": "Royaume-Uni",
        "US": "États Unis",
        "UM": "Îles mineures éloignées des États-Unis",
        "UY": "Uruguay",
        "VI": "Îles Vierges des États-Unis",
        "UZ": "Ouzbékistan",
        "VU": "République de Vanuatu",
        "VA": "Saint-Siège (Etat de la Cité du Vatican)",
        "VE": "Venezuela",
        "VN": "Viêt Nam",
        "WF": "Territoire des Îles Wallis et Futuna",
        "EH": "Sahara Occidental",
        "YE": "Yémen",
        "ZM": "Zambie",
        "ZW": "Zimbabwe"
    },
    POWER_BI:{
        title: "Formation Power BI",
        desc: "Apprenez l'analyse des donnees avec l'outils Microsoft Power BI"
    },
    FORM: {
        cardName:"Nom sur la Carte",
        cardNum: "Numéro de la carte",
        cardExpM: "Mois d'expiration",
        cardExpY: "Année d'expiration",
        cardCVV: "CVV",
        firstName: "Nom",
        lastName: "Prénom",
        profession: "Proféssion",
        email: "Adresse Email",
        phone: "Téléphone",
        terms: "Termes",
        accept: "Accepter nos",
        privacy: "Politiques de Confidentialité",
        savenPay: "S'enregistrer et Payer",
        savenTA: "S'enregistrer et prendre rendez-vous",
        appointmentMSG:"Afin de mieux nous adapter à votre emploi du temps, notre équipe vous contactera à partir de 19h00, 24 heures après que vous ayez rempli le formulaire.",
        payNum: "Numéro Payeur (Sans code du pays)",
        country: "Pays",
        paymentDesc:"Merci de confirmer votre mode de paiement pour finaliser l'inscription.",
        processPay: "Valider",
        priceBT: "Prix Hors Taxe",
        vat:"T.V.A",
        netToPay: "Net à Payer",
        success:"Votre paiement a été effectué avec succès. Vous recevrez votre facture par email !",
        success_:"Enregistrement Réussi. Vous serez contacté pour plus d'information!",
        errorReg:"Echec de l'enregistrement. Verifiez vos informations. Si l'erreur persiste, Reéssayez plus tard",
        errorPay: "Echec du paiement. Verifiez vos informations. Si l'erreur persiste, Reéssayez plus tard",
        noMobileP: "Pour les autres pays de la zone OHADA seul les paiements par carte sont disponibles",
        paymentInit: "Payment initié veuillez vérifier votre téléphone"
    },
    FORMERROR:{
        firstName: "Entrez Votre Nom",
        profession: "Fournissez Votre Proféssion",
        email: "Fournissez une Adresse email Valide",
        phone: "Numéro de Téléphone Réquis",
        terms: "Validez les termes et conditions",
        country: "Select Your Country",
    },
    CONJUCTION:{
        our: "Nos",
        our_: "Notre",
        and: "et",
    },
    TERMS_CONDITIONS: {
        title:"Termes et Conditions d'utilisation de B.K. Research & Analytics ",
        lastUpdate: "Derniere Mise à jour: 21 Juin 2024",
        section1: "En accédant à ce site web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser B. K. Research & Analytics si vous n'acceptez pas tous les termes et conditions énoncés sur cette page.",
        section2: "La terminologie suivante s'applique aux présentes conditions générales, à la déclaration de confidentialité, à l'avis de non-responsabilité et à tous les accords : \"Client\", \"Vous\" et \"Votre\" se réfèrent à vous, la personne qui se connecte à ce site Internet et qui se conforme aux conditions générales de l'Entreprise. \"L'Entreprise\", \"Nous\", \"Nous\", \"Notre\" et \"Nos\", fait référence à notre Entreprise. Le terme \"Parties\", \"Parties\" ou \"Nous\" désigne à la fois le Client et nous-mêmes. Tous les termes se réfèrent à l'offre, à l'acceptation et à la considération du paiement nécessaire pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée dans le but exprès de répondre aux besoins du Client en ce qui concerne la fourniture des services déclarés de la Société, conformément et sous réserve de la loi en vigueur aux Pays-Bas. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, avec une majuscule et/ou il/elle ou ils/elles, est considérée comme interchangeable et donc comme se référant à la même chose.",
        cookies1: "Nous utilisons des cookies. En accédant à B. K. Research & Analytics, vous acceptez d'utiliser les cookies conformément à la politique de confidentialité de B. K. Research & Analytics.",
        cookies2: "La plupart des sites web interactifs utilisent des cookies pour nous permettre de récupérer les détails de l'utilisateur à chaque visite. Les cookies sont utilisés par notre site web pour permettre la fonctionnalité de certaines zones afin de faciliter la tâche des personnes qui visitent notre site web. Certains de nos partenaires affiliés/publicitaires peuvent également utiliser des cookies.",
        pluginsTitle: "Plugins sur notre site web",
        pluginsDesc: "Sur notre site web, nous utilisons des plugins de réseaux sociaux provenant des réseaux sociaux énumérés ci-dessous :",
        pluginsX:"X (ex Twitter)",
        pluginsX1: "Sur notre site Web, des fonctions du service Twitter sont intégrées. Ces fonctions sont proposées par Twitter Inc., Twitter, Inc. 1355 Market St, Suite 900, San Francisco, CA 94103, USA. En utilisant Twitter et la fonction « Re-tweet », les sites Internet visités sont reliés à votre compte Twitter et publiés pour les autres utilisateurs. Ce faisant, des données sont transmises à Twitter. En tant que fournisseur des pages, nous attirons votre attention sur le fait que nous n’avons pas connaissance des informations fournies ni de leur utilisation par Twitter. Vous trouverez de plus amples informations à ce sujet dans la déclaration sur la protection des données de Twitter à l’adresse suivante : http://twitter.com/privacy. <br/> Sur Twitter, vous pouvez modifier les paramètres de protection des données sur votre compte à l’adresse : http://twitter.com/account/settings.<br/> Afin d’empêcher que Twitter ne collecte les données lors de la visite de notre site Web, déconnectez-vous de Twitter avant la visite. Afin d’empêcher un accès général de Twitter à vos données sur des sites Internet, vous pouvez exclure les plugins Twitter par un add-on pour votre navigateur (par ex. « Twitter-Blocker », https://disconnect.me).",
        pluginsLk: "LinkedIn",
        pluginsLk1: "Sur notre site web, des plugins du réseau social LinkedIn sont intégrés. Le fournisseur est LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. En cliquant sur le bouton LinkedIn, une connexion est établie avec les serveurs de LinkedIn. Le contenu du plugin est transmis directement par LinkedIn à votre navigateur et est intégré par celui-ci dans le site web. Ainsi, l'information selon laquelle vous avez visité notre site est transmise à LinkedIn. Si vous êtes connecté à votre compte LinkedIn, en cliquant sur le bouton LinkedIn, votre visite sur notre site peut être attribuée directement à votre profil. Même si vous n'avez pas de profil, il n'est pas possible d'exclure que votre adresse IP soit enregistrée par LinkedIn.<br/> En tant que fournisseur des pages, nous tenons à souligner que nous n'avons aucune connaissance des informations fournies ni de la manière dont elles sont utilisées par LinkedIn. Vous trouverez de plus amples informations sur la déclaration de protection des données de LinkedIn à l'adresse suivante : https://www.linkedin.com/legal/privacy-policy",
        pluginsInsta: "Instagram",
        pluginsInsta1: "Sur notre site web se trouve le bouton social utilisé par Instagram. Celui-ci est uniquement intégré à la page sous la forme d'un lien HTML, de sorte que lorsque vous ouvrez notre site web, aucune connexion n'est encore établie avec les serveurs d'Instagram. Si vous cliquez sur le bouton, le site web du réseau social concerné s'ouvrira dans une nouvelle fenêtre du navigateur. Vous pouvez, par exemple, appuyer sur le bouton \"J'aime\" ou \"Partager\".<br/> Instagram est un service de Facebook Ireland Ltd, 4 Grand Canal Square, Dublin 2, Ireland (\N- \"Facebook Ireland\"). Les informations collectées automatiquement par Facebook Ireland concernant votre utilisation de notre présence en ligne sur Instagram sont généralement transmises à un serveur de Facebook, Inc, 1601 Willow Road, Menlo Park, California 94025, USA et y sont stockées.<br/>Vous trouverez la déclaration de protection des données d'Instagram ici : https://help.instagram.com/519522125107875",
        pluginsFB: "Facebook",
        pluginsFB1: "Sur notre site web se trouve le bouton social utilisé par Facebook. Celui-ci n'est intégré à la page que sous la forme d'un lien HTML, de sorte que lorsque vous ouvrez notre site web, aucune connexion n'est encore établie avec les serveurs d'Instagram. Si vous cliquez sur le bouton, le site web du réseau social concerné s'ouvrira dans une nouvelle fenêtre du navigateur. Vous pouvez, par exemple, appuyer sur le bouton \"Like\" ou \"Share\". <br/> Facebook est un réseau social exploité par Facebook Inc. à 1 Hacker Way, Menlo Park, California 94025, États-Unis (ci-après dénommé \"Facebook\"). Les informations collectées automatiquement par Facebook concernant votre utilisation de notre présence en ligne sur Facebook sont généralement transmises à un serveur de Facebook et y sont stockées. Vous pouvez consulter la déclaration de protection des données de Facebook à l'adresse suivante : https://www.facebook.com/about/privacy/",
        license:"License",
        license1: "Sauf indication contraire, B. K. Research & Analytics et/ou ses concédants de licence détiennent les droits de propriété intellectuelle sur l'ensemble du contenu du site de B. K. Research & Analytics. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez accéder à ce site de B. K. Research & Analytics pour votre usage personnel, sous réserve des restrictions énoncées dans les présentes conditions générales. <br/> Vous devez",
        license2: "Reproduction de documents de B. K. Research & Analytics",
        license3: "Vendre, louer ou donner en sous-licence du matériel de B. K. Research & Analytics",
        license4: "Reproduire, dupliquer ou copier du matériel de B. K. Research & Analytics",
        license5: "Redistribuer le contenu de B. K. Research & Analytics",
        license6: "Le présent accord entre en vigueur à sa date d'entrée en vigueur. Nos conditions générales ont été créées avec l'aide du générateur de conditions générales.<br/>PLes arts de ce site web offrent aux utilisateurs la possibilité de publier et d'échanger des opinions et des informations dans certaines zones du site web. B. K. Research & Analytics ne filtre pas, n'édite pas, ne publie pas et ne vérifie pas les commentaires avant qu'ils ne soient publiés sur le site web. Les commentaires ne reflètent pas les points de vue et les opinions de B. K. Research & Analytics, de ses agents et/ou de ses affiliés. Les commentaires reflètent les points de vue et les opinions de la personne qui les a publiés. Dans la mesure où les lois applicables le permettent, B. K. Research & Analytics ne peut être tenu responsable des commentaires ou de toute responsabilité, dommage ou dépense causé(e) et/ou subi(e) à la suite de l'utilisation et/ou de la publication et/ou de l'apparition des commentaires sur ce site web.<br/>B. K. Research & Analytics se réserve le droit de contrôler tous les commentaires et de supprimer tout commentaire pouvant être considéré comme inapproprié, offensant ou constituant une violation des présentes conditions générales.<br/>Vous garantissez et déclarez que:",
        license7: "Vous avez le droit de publier les commentaires sur notre site web et disposez de toutes les licences et autorisations nécessaires à cet effet.",
        license8: "Les commentaires n'enfreignent aucun droit de propriété intellectuelle, y compris, mais sans s'y limiter, les droits d'auteur, les brevets ou les marques déposées d'un tiers.",
        license9: "Les commentaires ne contiennent pas d'éléments diffamatoires, offensants, indécents ou illégaux portant atteinte à la vie privée.",
        license10: "Les commentaires ne seront pas utilisés pour solliciter ou promouvoir des affaires ou des coutumes, ni pour présenter des activités commerciales ou des activités illégales.",
        license11: "Par la présente, vous accordez à B. K. Research & Analytics une licence non exclusive pour utiliser, reproduire, éditer et autoriser d'autres personnes à utiliser, reproduire et éditer vos commentaires sous quelque forme, format ou média que ce soit.",
        hyperLinking: "Hyperliens vers notre contenu",
        hyperLinking1: "Les organisations suivantes peuvent établir un lien avec notre site web sans autorisation écrite préalable :",
        hyperLinking2: "Agences gouvernementales",
        hyperLinking3: "Moteurs de recherche",
        hyperLinking4: "Organismes de presse",
        hyperLinking5: "Les distributeurs d'annuaires en ligne peuvent établir des liens vers notre site web de la même manière qu'ils établissent des liens hypertextes vers les sites web d'autres entreprises répertoriées",
        hyperLinking6: "Les entreprises accréditées à l'échelle du système, à l'exception des organisations à but non lucratif, des centres commerciaux caritatifs et des groupes de collecte de fonds caritatifs qui ne peuvent pas créer d'hyperliens vers notre site Web.",
        hyperLinking7: "Ces organisations peuvent établir un lien avec notre page d'accueil, nos publications ou d'autres informations du site Web, à condition que le lien soit établi: (a) n'est en aucune façon trompeuse ; (b) n'implique pas faussement le parrainage, l'approbation ou la caution de la partie qui établit le lien et de ses produits et/ou services ; et (c) s'inscrit dans le contexte du site de la partie qui établit le lien.<br/>Nous pouvons prendre en considération et approuver d'autres demandes de liens émanant des types d'organisations suivants :",
        hyperLinking8: "les sources d'information connues des consommateurs et/ou des entreprises",
        hyperLinking9: "Sites communautaires .com",
        hyperLinking10: "les associations ou autres groupes représentant les organismes caritatifs",
        hyperLinking11: "annuaire en ligne distributeurs",
        hyperLinking12: "portails internet",
        hyperLinking13: "les cabinets d'experts-comptables, d'avocats et de consultants et",
        hyperLinking14: "les établissements d'enseignement et les associations professionnelles.",
        hyperLinking15: "Nous approuverons les demandes de liens émanant de ces organisations si nous estimons que (a) le lien ne nous donnera pas une image défavorable de nous-mêmes ou de nos entreprises accréditées; (b) l'organisation n'a pas d'antécédents négatifs avec nous ; (c) l'avantage que nous tirons de la visibilité du lien hypertexte compense l'absence de B. K. Research & Analytics; Ces organisations peuvent établir un lien avec notre page d'accueil à condition que le lien.. : (a) ne soit en aucun cas trompeur; (b) n'implique pas faussement un parrainage, un soutien ou une approbation de la partie qui établit le lien et de ses produits ou services ; et (c) s'inscrit dans le contexte du site de la partie qui établit le lien.<br/>Si vous êtes l'une des organisations énumérées au paragraphe 2 ci-dessus et que vous souhaitez créer un lien vers notre site web, vous devez nous en informer en envoyant un courrier électronique à B. K. Research & Analytics. Veuillez inclure votre nom, le nom de votre organisation, vos coordonnées ainsi que l'URL de votre site, une liste des URL à partir desquels vous avez l'intention de créer un lien vers notre site web et une liste des URL de notre site vers lesquels vous aimeriez créer un lien. Attendez 2 à 3 semaines pour une réponse.<br/>Les organisations agréées peuvent établir des liens hypertextes vers notre site web de la manière suivante :",
        hyperLinking16: "Par l'utilisation de notre raison sociale ; ou",
        hyperLinking17: "Par l'utilisation du localisateur de ressources uniformes vers lequel le lien est établi ; ou",
        hyperLinking18: "Par l'utilisation de toute autre description de notre site web qui a du sens dans le contexte et le format du contenu du site de la partie qui établit le lien.",
        hyperLinking19: "Aucune utilisation du logo ou d'autres illustrations de B. K. Research & Analytics ne sera autorisée pour l'établissement de liens en l'absence d'un accord de licence de marque.",
        iFrame: "iFrames",
        iFrame1: "Sans accord préalable et autorisation écrite, vous ne pouvez pas créer de cadres autour de nos pages web qui modifient de quelque manière que ce soit la présentation visuelle ou l'apparence de notre site web.",
        contLiab: "Responsabilité en matière de contenu",
        contLiab1: "Nous ne serons pas tenus responsables du contenu qui apparaît sur votre site web. Vous acceptez de nous protéger et de nous défendre contre toute réclamation concernant le contenu de votre site web. Aucun lien ne doit apparaître sur un site web qui pourrait être interprété comme diffamatoire, obscène ou criminel, ou qui enfreint, viole ou préconise l'enfreinte ou la violation des droits d'un tiers."

    }
}