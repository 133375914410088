import { useEffect, useState } from "react";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer} from "react-toastify";
import { MASTERCARD_LOGO,VISA_LOGO, VERVE_LOGO, OM_LOGO, MOMO_LOGO, POWER_BI_TRAINING} from "../../components/utils/constants";
import MobilePayment from "./mobilePayment";
import { PARAM_API, PAYMENT_API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { curencyFormater, GAActionTrackingFunction, percentageFormater, validateEmail, validatePhoneNumber } from "../../components/utils/finctions";
import LangSwitcher from "../../components/lang/langSwitcher";
import LoaderPage from "../../components/loader";
import axios from "axios";
import { showSuccessPage } from "./successPane";
import CardPayment from "./cardPayment";
const westCountriesCode = ["BJ","BF","SN","CI","KM","GN","GW","ML","NE","TG"] // XOF
const centerCountriesCode = ["CM","CF","TD","CD","GQ","GB","CG"] // XAF
const mobileMoneyCountriesCode = ["BF","CM","SN","CI"]
const contryFetcher = (url) => PARAM_API.get(url).then(res => res.data.results);
const productsFetcher = (url) => PAYMENT_API.get(url).then(res => res.data.data);
const TrainingPayment = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [validFirstName, setValidFirstName] = useState(true);
    const [lastName, setLastName] = useState("");
    const [profession, setProfession] = useState(null);
    const [validProfession, setValidProfession] = useState(true);
    

    const {data: countries, loadinCountries} = useSWR(URLS.PARAMS.COUNTRY.ohada(400),contryFetcher);
    const {data: product, loadingProduct} = useSWR(URLS.PAYMENT_APP.PRODUCTS.get(id), productsFetcher);

    const [email, setEmail] = useState(null);
    const [emailValid, setEmailValid] = useState(true);

    const [phoneNumber, setPhoneNumber] = useState();
    const [phoneValid, setPhoneValid] = useState(true);

    const [payerPhoneNumber, setPayerPhoneNumber] = useState();
    const [payerPhoneValid, setPayerPhoneValid] = useState(true);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [validCountry, setValidCountry] = useState(true);
    const [nameInvalid, setnameInvalid] = useState(false);
    const [cardName, setCardName] = useState(null);
    const [invalidCarnum, setinvalidCarnum] = useState(false);
    const [cardNum, setCardNum] = useState(null);
    const [noExpMonth, setnoExpMonth] = useState(false);
    const [noExpYear, setnoExpYear] = useState(false);
    const [expYear, setexpYear] = useState(null)
    const [expMonth, setexpMonth] = useState(null)
    const [CVV,setCVV] = useState(null);
    const [noCVV, setNoCVV] = useState(false);

    const [agree, setAgree] = useState(false);
    const [agreed, setAgreed] = useState(true);
    const [pMethod, setPMethod] = useState("OM")
    const [makePayment, setMakePayment] = useState(false);
    const [africFranco, setAfricFranco] = useState([]);
    const [success, setSuccess] = useState(false)
    const setMetod = (methode) =>{

    }
    
    const onSubmit=()=>{
        setLoading(true)
        if(validate_data()){
            const data = prepareData()
            PAYMENT_API.post(URLS.PAYMENT_APP.CUSTOMER.create(),data).then(resp=>{
                if(makePayment){
                    toast.info(t("FORM.paymentInit"), {position:toast.POSITION.TOP_CENTER, theme:"colored"})
                    GAActionTrackingFunction("Payment Taining-Registration");
                    toast.success()
                    processPayment(resp.data.id);
                }else{
                    GAActionTrackingFunction("Taining Registration");
                    createTicket(resp.data);
                }
            }).catch(err=>{
                console.log(err);
                setLoading(false)
                showSuccessPage(t, t("FORM.errorReg"),"failed")
            })
        }else{
            setLoading(false)
        }
    }
    const validate_data=()=>{
        setValidFirstName(true);
        setValidProfession(true);
        setEmailValid(true);
        setPhoneValid(true);
        setAgreed(true);
        setValidCountry(true);
        setPayerPhoneValid(true);

        setnameInvalid(false);
        setinvalidCarnum(false);
        setnoExpYear(false);
        setnoExpMonth(false);
        setNoCVV(false);
        

        if(!firstName){
            setValidFirstName(false);
            // toast.error("Provide Your firstname");
            return false;
        }
        if(!profession){
            setValidProfession(false);
            // toast.error("Provide Your profession");
            return false;
        }
        if(!email || !validateEmail(email)){
            setEmailValid(false)
            // toast.error("Providate a valid professionnal Email address");
            return false
        }
        if(!phoneNumber || !validatePhoneNumber(phoneNumber)){
            setPhoneValid(false)
            // toast.error("Invalid Phone Number");
            return false
        }
        if(!agree){
            setAgreed(false)
            // toast.error("Agree on our terms and privacy policies");
            return false
        }
        if(makePayment){
            if((pMethod==="OM" || pMethod==="MOMO") && (!payerPhoneNumber || !validatePhoneNumber(payerPhoneNumber))){
                setPayerPhoneValid(false);
                // toast.error("Provide a valid phon number")
                return false
            }

            if (pMethod==="CARD" && !cardName){
                setnameInvalid(true);
                return false
            }
            if (pMethod==="CARD" && (!cardNum || String(cardNum).length<15)){
                setinvalidCarnum(true);
                return false
            }
            if (pMethod==="CARD" && !expMonth){
                setnoExpMonth(true);
                return false
            }
            if (pMethod==="CARD" && !expYear){
                noExpYear(true);
                return false
            }
            if (pMethod==="CARD" && !CVV){
                setNoCVV(true);
                return false
            }
            if(!selectedCountry){
                setValidCountry(false)
                // toast.error("Selected Your country");
                return false
            }
        }
        return true;
    }
    const createTicket = (customer) => {
        const data = {
            "customer":customer.id,
            "severity":"HIGH",
            "subject":`${product?.label}`,
            "message": `Subcribing to training ${product?.label}`
        }
        PAYMENT_API.post(URLS.PAYMENT_APP.CUSTOMER.ticket(),data).then((resp)=>{
            setLoading(false);
            showSuccessPage(t, t("FORM.success_"),'success');
        }).catch((err)=>{
            console.log(err);
            setLoading(false)
            showSuccessPage(t, t("FORM.errorReg"),"failed")
        })
    }
    const processPayment=(customer)=>{
        const payload = {
            "country":selectedCountry.code,
            "mode":pMethod,
            "product":id,
            "currency":westCountriesCode.includes(selectedCountry.code)?"XOF":"XAF",
            "email":email,
            "customer": customer
        }
        if(pMethod==="CARD"){
            payload['cvv'] = CVV;
            payload['card_number']=cardNum;
            payload['name'] = cardName;
            payload['expiry_month'] = expMonth.value;
            payload['expiry_year'] = expYear.value;
        }else{
            payload["phone"]=`${selectedCountry.phone}${payerPhoneNumber}`
        }
        const url = pMethod==="CARD"?URLS.PAYMENT_APP.PAYMENT.card():URLS.PAYMENT_APP.PAYMENT.mobile();
        console.log(payload);
        PAYMENT_API.post(url, payload).then((resp)=>{
            setLoading(false);
            showSuccessPage(t, t("FORM.success"),'success');
        }).catch((err)=>{
            console.log(err);
            setLoading(false);
            showSuccessPage(t, t("FORM.errorPay"),'failed');
        })
    }
    const prepareData=()=>{
        return {
            "name": firstName,
            "surname": lastName,
            "phone": selectedCountry?.phone?`+${selectedCountry.phone}${phoneNumber}`:`${phoneNumber}`,
            "email": email,
            "country":selectedCountry?.code
        }
    }
    const showPaymentView = () => {
        // console.log(countries)
        if(validate_data()){
            let francoCountries = []
            countries.forEach((item)=>{
                console.log(item.code);
                if(mobileMoneyCountriesCode.includes(item.code2)){
                    francoCountries.push(item);
                }
            });
            setAfricFranco(francoCountries);
            setMakePayment(true);
        }
    }
    useEffect(()=>{
        document.title = "B.K. Research & Analytics | Trainning"
     },[]);
    return (
        <>
        {(loadinCountries || loadingProduct)?(
            <LoaderPage/>
        ):
        (
        <section 
        className={loading?"disabled":""}
        style={{background:'url("/assets/img/faq/bg-shape.png")', backgroundPosition:"cover", backgroundSize:"cover", backgroundRepeat:"no-repeat", marginBottom:10, top:0, margin:0, minHeight:"100vh"}}
        >
            <div className="container-fluid trainingreg" style={{marginTop:"0",}}>
                <div className="row" style={{display: 'flex',justifyContent:'center'}}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 servicereg mt-4" style={{alignItems:"center"}}>
                        <div className="tp-faq-tab-content tp-accordion wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.3s" style={{height:"100%"}}>
                            <div className="accordion" id="general_accordion" style={{padding:"0%"}}>
                                <Link to="/" className="tp-section-title-pre">
                                    <img
                                        alt="B.K. Research"
                                        src="/assets/img/logo/logo_small.png"
                                        className="app-logo"
                                    />
                                </Link>
                                <div className="form-group mb-3">
                                    {!validFirstName && <p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.firstName")}</p>}
                                    {validFirstName && <label htmlFor="firstName">{t('FORM.firstName')} <span className="text-danger">*</span></label>}
                                    <input className="form-control" id="firstName" type="text" value={firstName} placeholder="Nkolo" name="firstName" onChange={(e)=>{setFirstName(e.target.value)}}  disabled={makePayment}/>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="lastName">{t('FORM.lastName')} </label>
                                    <input className="form-control" id="lastName" type="text" value={lastName} placeholder="Jean Vincent" name="lastName" onChange={(e)=>{setLastName(e.target.value)}}  disabled={makePayment}/>
                                </div>
                                <div className="form-group mb-3">
                                    {!validProfession && <p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.profession")}</p>}
                                    {validProfession && <label htmlFor="profession">{t('FORM.profession')} <span className="text-danger">*</span></label>}
                                    <input className="form-control" id="profession" type="text" placeholder="Analyst" name="profession" onChange={(e)=>{setProfession(e.target.value)}}  disabled={makePayment}/>
                                </div>
                                <div className=" form-group mb-3">
                                    {!emailValid && <p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.email")}</p>}
                                    {emailValid && <label htmlFor="email">{t('FORM.email')} <span className="text-danger">*</span></label>}
                                    <input className="form-control" id="email" type="text" placeholder="contact@bkresearhandanalytics.com" name="email" onChange={(e)=>{setEmail(e.target.value)}}  disabled={makePayment}/>
                                </div>
                                <div className="form-group mb-3">
                                    {!phoneValid && <p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.phone")}</p>}
                                    {phoneValid &&<label htmlFor="phoneNumber">{t('FORM.phone')} <span className="text-danger">*</span></label>}
                                    <input className="form-control" id="phoneNumber" type="number" placeholder="237690807060" name="phoneNumber" onChange={(e)=>{setPhoneNumber(e.target.value)}}  disabled={makePayment}/>
                                </div>
                                <div className="row row-xs">
                                    <div className="col-12">{!agreed && <p className="text-danger" style={{float:"left", fontSize:"9pt"}}>{t("FORMERROR.terms")}</p>}</div>
                                    <div className="col-10">
                                        <span>{t('FORM.accept')} <Link to="/privacy-policy"style={{fontWeight:"bold", textDecoration:"underline"}} target="_blank">{t('FORM.terms')} {t('CONJUCTION.and')} {t('FORM.privacy')}</Link>?</span>
                                    </div>
                                    <div className="col-2 mt-3 mt-md-0">
                                        <input type="checkbox" htmlFor="acceptTerm" name="acceptTerm" onChange={(e)=>{setAgree(!agree)}}/>
                                    </div>
                                </div>
                                {!makePayment &&(
                                    <>
                                        <div className="row row-xs">
                                            <div className="col-12">
                                                <button className="btn btn-primary btn-block mt-2 w-100 form-btn" disabled={loading} style={{minHeight:"50px"}} onClick={(e)=>{showPaymentView()}}>
                                                    {loading && (
                                                        <div className="ladda-button basic-ladda-button"></div>
                                                    )}
                                                    {!loading && t('FORM.savenPay')}</button>
                                            </div>
                                            <div className="col-12 text-center">
                                                <div className="mt-10">
                                                    <p className="mt-5" style={{fontSize:"8pt", lineHeight:1.5}}>{t('FORM.appointmentMSG')}</p>
                                                </div>
                                                <button className="btn btn-primary btn-block mt-2 w-100 form-btn" disabled={loading} style={{minHeight:"50px"}} onClick={(e)=>{onSubmit()}}>
                                                    {loading && (
                                                        <div className="ladda-button basic-ladda-button"></div>
                                                    )}
                                                    {!loading && t('FORM.savenTA')}</button>
                                            </div>
                                        </div>
                                    </>)}
                                <div  style={{display: 'flex',justifyContent:'center'}}>
                                    <LangSwitcher/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7"> */}
                    {makePayment && (<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 servicepay wow fadeInUp mt-4" data-wow-duration="3s" data-wow-delay="0.4s">
                        <div className="tp-faq-tab-content">
                        <div className="">
                            <div className="payment-method">
                                <button className={pMethod==="OM"?"btn selected-btn m-btn":"btn m-btn"} onClick={()=>setPMethod("OM")}>
                                    <img src={OM_LOGO} className="img-btn" alt="" srcSet=""/>
                                </button>
                                <button className={pMethod==="MOMO"?"btn selected-btn m-btn":"btn m-btn"} onClick={()=>setPMethod("MOMO")}>
                                    <img src={MOMO_LOGO} className="img-btn" alt="" srcSet="" />
                                </button>
                                <button className={pMethod==="CARD"?"btn selected-btn m-btn":"btn m-btn"} onClick={()=>setPMethod("CARD")}>
                                    <img src={MASTERCARD_LOGO} style={{width: "50px", height:"30px",}} alt="" srcSet=""/>
                                    <img src={VISA_LOGO} style={{width: "50px", height:"50px",}} alt="" srcSet=""/>
                                    <img src={VERVE_LOGO} style={{width: "50px", height:"30px",}} alt="" srcSet=""/>
                                </button>
                            </div>
                        </div>
                        
                        {(pMethod==="MOMO" || pMethod==="OM")?(<MobilePayment phoneValid={payerPhoneValid} setPhoneNumber={setPayerPhoneNumber} countries={africFranco} 
                        validCountry={validCountry} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} t={t} submit={onSubmit}/>):(
                        <CardPayment loading={loading} validCountry={validCountry} selectedCountry={selectedCountry} 
                        setSelectedCountry={setSelectedCountry} t={t} countries={countries} submit={onSubmit} nameInvalid={nameInvalid} setCardNum={setCardNum}
                        setCardName={setCardName} invalidCarnum={invalidCarnum} setexpYear={setexpYear} noExpYear={noExpYear} setexpMonth={setexpMonth} 
                        noExpMonth={noExpMonth} noCVV={noCVV} setCVV={setCVV} expYear={expYear} expMonth={expMonth}/>)}
                        </div>
                    </div> )}
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 servicedetails y wow fadeInDown mt-4" data-wow-duration="4s" data-wow-delay="0.4s">
                        <div className="card">
                            <div className="container">
                                <div className="row prodtitle">
                                    <div className="col-4">
                                        <img src={product?.ref?.includes("POWER_BI")&&POWER_BI_TRAINING} alt="" className="prod-logo"/>
                                    </div>
                                    <div className="col centerHV">
                                        {product?.ref?.includes("POWER_BI")&&
                                        <h4 className="mt-5 text-center">{t("POWER_BI.title")}</h4>}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                    {product?.ref?.includes("POWER_BI")&&<p className="text-center">{t("POWER_BI.desc")}</p>}</div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6" style={{textAlign:"left"}}>
                                        <p style={{fontSize:"9pt"}}>{t("FORM.priceBT")}</p>
                                    </div>
                                    <div className="col-6 training-amount">
                                        <p style={{fontSize:"9pt"}}>{curencyFormater(product?.price)}</p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6" style={{textAlign:"left"}}>
                                    <p style={{fontSize:"9pt"}}>{t("FORM.vat")}</p>
                                    </div>
                                    <div className="col-6 training-amount" style={{color:"red"}}>
                                        <p style={{color:"red",fontSize:"9pt"}}>{percentageFormater(product?.tva_tx)}</p>
                                    </div>
                                </div>
                                <div className="row mt-5 total-amount">
                                    <div className="col-6 mt-3" style={{textAlign:"left"}}>
                                    <p style={{fontSize:"9pt"}}>{t("FORM.netToPay")}</p>
                                    </div>
                                    <div className="col-6 mt-3 training-amount">
                                        <p style={{fontSize:"9pt"}}>{curencyFormater(product?.price_ttc)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                {/* </div> */}
            </div>
        </section>
          )}
        </>
    )

}
export default TrainingPayment;