import './App.css';
import AppRoutes from './routes';
import { Provider } from 'react-redux'
import { store } from './store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigSWR from './services/swr';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { GA_TRACKING_ID } from './components/utils/constants';
// import "./translations/translations"



function App() {
  // ReactGA.initialize(GA_TRACKING_ID)
  useEffect(()=>{
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname
    })
  })

  return (
    <Provider store={store}>
      <ConfigSWR>
        <AppRoutes />
        <ToastContainer />
      </ConfigSWR>
    </Provider>
  );
}

export default App;